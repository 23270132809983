import { createRouter, createWebHistory } from 'vue-router'
import { DashBoard, BranchesView, Settings} from './AuthRoutes'

//Parent for all routes that require authentication
const AuthRoutes = {
  path:'/',
  redirect: '/dashboard',
  component:() => import('../views/AuthView.vue'),
  children:[
    DashBoard,
    BranchesView,
    Settings,
  ]

}

//Routes that don't require authentication (Login Route)
const Login = {
  path: '/login',
  name: 'Login',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "Branches" */ '../views/Login.vue')
}


const Logout = {
  path: '/logout',
  name: 'Logout',
  meta: {
    requiresAuth: true,
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "Branches" */ '../views/Logout.vue')
}

const NotFound = {
  path: '/:pathMatch(.*)*',
  name:'NotFound',
  component: () => import('../views/NotFound.vue'),
}



const routes = [
  Login,
  Logout,
  AuthRoutes,
  NotFound,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
