function splitCookieToFindToken(cookie){
    if (cookie) return cookie.split("; ").find((row) => row.startsWith("TOKEN")).split("=")[1]
}
export function JSONrequestConfig(token){
    return {
        headers: {
            "Content-Type": "application/json",
            ...(token && {"Authorization": `Token ${token}`})
        }
    }
}

export function formDataRequestConfig(token){
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            ...(token && {"Authorization": `Token ${token}`})
        }
    }
}



export function getToken(){   
    let token = splitCookieToFindToken(document.cookie)
  
    return token ? token : null
}

export function saveToken(token){
    document.cookie = `TOKEN=${encodeURIComponent(token)}; max-age=31536000; secure; samesite=none; path=/`;
}

export function deleteTokenCookie(){
    document.cookie = "TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}