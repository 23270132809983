<template>
    <div class="side-card">
		<h1 class="side-card--heading">Options</h1>
		<div class="side-card--section">
			<side-card-select name="timeline" :options="['dialy','montly','yearly']" />
		</div>
		<div class="side-card--section">
			<side-card-select name="currency" :options="['Dollar','Cedi','Naira']" />
		</div>
	</div>
</template>
<script>
import SideCardSelect from '@/components/SideCardSelect.vue'

export default {
  components: { SideCardSelect },
}
</script>