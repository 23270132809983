<template>
	<div class="content">

		<div class="dashboard__section dashboard__section--graph-main">
			<div class="dashboard__card">
					<bar-chart type="area" :dataset="[1,2,3,4,5,6,8]"/>
			</div>
		</div>

		<div class="dashboard__section dashboard__section--pined">
			<!-- <div class="dashboard__pinned">
				<div class="dashboard__card">
					<div class="dashboard__decription">staff</div>
					<div class="dashboard__value">2000</div>
				</div>
				<div class="dashboard__card">
					<div class="dashboard__decription">staff</div>
					<div class="dashboard__value">2000</div>
				</div>
				<div class="dashboard__card">
					<div class="dashboard__decription">staff</div>
					<div class="dashboard__value">2000</div>
				</div>
				<div class="dashboard__card">
					<div class="dashboard__decription">staff</div>
					<div class="dashboard__value">2000</div>
				</div>
			</div> -->
			<!-- <div class="dashboard__card">
			<pie-chart />
			</div> -->
		</div>

			<!-- <div class="dashboard__section dashboard__section--graph-secondary">
				<div class="dashboard__card">
					<bar-chart type="bar" :dataset="[1,2,3,4,5,6,8]"/>
				</div>
			</div> -->

			<!-- <div class="dashboard__section dashboard__section--lists">
				<div class="dashboard__card dashboard--list">
					<div class="dashboard__list-item">one</div>
					<div class="dashboard__list-item">two</div>
					<div class="dashboard__list-item">three</div>
					<div class="dashboard__list-item">four</div>
					<div class="dashboard__list-item">five</div>
				</div>
				<div class="dashboard__card dashboard--list">
					<div class="dashboard__list-item">one</div>
					<div class="dashboard__list-item">two</div>
					<div class="dashboard__list-item">three</div>
					<div class="dashboard__list-item">four</div>
					<div class="dashboard__list-item">five</div>
				</div>
			</div> -->
	</div>
</template>

<script>
import BarChart from '@/components/charts/DashboardChart.vue'
// import PieChart from '@/components/charts/PieChart.vue'
export default {
  components: { BarChart, },
  mounted(){
	this.$store.commit({type:'stopAuthenticationLoader'})
  }
}
</script>