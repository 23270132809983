import api from '@/services/api'
import { JSONrequestConfig,saveToken, getToken, deleteTokenCookie } from '@/helpers/userAuthentication';

const logIn = ({commit},payload) => {

    const requestBody = {
      username:payload.username,
      password:payload.password
    }
  
    return new Promise((resolve,reject)=>{
      api.post(`/users/login/`,
            requestBody,
            JSONrequestConfig()) 
      .then((response)=>{
        commit({
          type: 'authenticate',
          user: response.data.user
        })
        saveToken(response.data.token)
        resolve()
      })
      
      .catch( (feelings) => reject(feelings))
    });
}

const logOut = ({commit}) => {
    return new Promise((resolve, reject) => {
      api
        .post("/users/logout/", null,JSONrequestConfig( getToken() ))
        .then((response) => {
          commit({type:'unAuthenticate'})
          console.log('stop loading')
          commit({type:'resetState'})
          deleteTokenCookie()
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    });
}

const AuthenticateToken = ({ commit }) => {
    commit({type:'startAuthenticationLoader'})
    return new Promise((resolve, reject) => {
      if( !getToken() ) reject()
      else{
        api
          .get("/users/user/get_current_user/", JSONrequestConfig( getToken() ))
          .then((response) => {
            commit({type:'authenticate',user:response.data});
            console.log(response.data)
            resolve();
          })
          .catch(() => {
            commit({type:'stopAuthenticationLoader'})
            // console45 tgmkv   gat h`.log(e.response.data)
            reject()
          });
      }
    });
  }

export default {
    logIn,
    logOut,
    AuthenticateToken,
}