const ConsignmentsModalRegister = {
  name:'consignmentRegister',
  path:'register',
  meta:{
    M_TITLE:{modifier:'register',type: 'consignment'},
    M_REQUESTS:{load:'products/loadAllProducts',register:'consignments/registerConsignment'},
    M_RESQUEST_DATA_FILTER:{
      register:[{name:'id',substitute:'product'},{name:'quantity',substitute:'quantity_consigned'}]
    },
    M_RESPONSE_DATA_FILTER:{listName:'consigned_products',},
    RENDER_INPUTS:{
      reference: {type:'text',substitute:'reference'},
      description: {type:'description',substitute:'description'},
    },
  },
  component: () => import('@/components/Modals/ModalContainer.vue')
}
export default{
    path: '/consignments/:id',
    name: 'consignments', 
    props: true,
    meta: {
      index:2,
      requiresVendorPermission:true,
      OPTIONS:{sideSelector:true, state:{name:'consignments',data:'consignments',userRole:'can_create_consignments'} },
      TITLE_BAR_DATA:{
        name:{singular:'Consignment',plural:'Consignments'},
        buttons:[{name:'Consignment',pathName:'consignmentRegister'}]
      },
      REQUESTS:{load:'consignments/loadConsignments'},

      RESPONSE_DATA_FILTER:{
        header:{reference:'',description:''},
      }, 
    },
    component: () => import('@/components/List.vue'),
    children:[ConsignmentsModalRegister]
  }