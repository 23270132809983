const OrdersModalRegister = {
  name:'ordersRegister',
  path:'register',
  meta:{
    M_TITLE:{modifier:'register',type: 'order'},
    M_REQUESTS:{load:'products/loadAllProducts',register:'orders/registerOrders'},
    M_RESQUEST_DATA_FILTER:{
      productSellingPrice: true,
      register:[
        {name:'id',substitute:'item_id'},
        {name:'unit_selling_price',substitute:'price'},
        {name:'quantity',substitute:'quantity'},
        {name: 'name', substitute:'name'}
      ]
    },
    M_RESPONSE_DATA_FILTER:{listName:'order_items',},
    RENDER_INPUTS:{
      name: {type:'text',substitute:'name'},
      client_phone: {type:'text',substitute:'Contact Phone',isPhone:true},
      comment: {type: 'description', substitute: 'comment'}
    },
  }, 
  component: () => import('@/components/Modals/ModalContainer.vue')
}

export default{
    path: '/orders/:id',
    name: 'orders',
    props: true,
    
    meta: {
      index:3,
      OPTIONS:{sideSelector: true,state:{name:'orders',data:'orders'}},
      TITLE_BAR_DATA:{
        search:true,
        name:{singular:'Order',plural:'Orders'},
        buttons:[{name:'Order',pathName:'ordersRegister',userRole:'can_create_orders'}]
      },
      REQUESTS:{load:'orders/loadOrders',isPolling:true,},

      RESPONSE_DATA_FILTER:{
        header:{
          name:'',
          client_phone:{substitute:'client phone'},
          is_pending:{substitute:'status'},
          total:'',
          attendant:'',
          modified:'',
        }
      }, 

    },
    component: () => import('@/components/List.vue'),
    children:[OrdersModalRegister]
  }
