const toggleModal = (state)=>{
    state.modal_open = !state.modal_open
}

const startAuthenticationLoader = state => { state.loaderAuth = true }
const stopAuthenticationLoader = state => { 
    state.loaderAuth = false
    state.error = false
 }

 const stopAuthenticationLoaderWithError = state => { 
    state.loaderAuth = false
    state.error = true
 }

const authenticate = (state,payload) => {
    state.isAuthenticated = true
    state.user = payload.user
}

const unAuthenticate = state => {
    state.isAuthenticated = false
    state.user = {}
}


const setBranchId = (state,payload)=>{
    state.branchId = payload.branchId
}


const setHeaderSearchQuery = (state,payload)=>{
    state.headerSearchQuery = payload.headerSearchQuery
}



const addError = (state,payload) =>{
    /*prototype of payload.error = {name}*/
    state.error = payload.error
}

const setMobileSideNav = (state,payload) =>{
    state.mobileSideNav = payload.mobileSideNav
}




export default {
    toggleModal,
    startAuthenticationLoader,
    stopAuthenticationLoaderWithError,
    stopAuthenticationLoader,
    authenticate,
    unAuthenticate,
    addError,
    setBranchId,
    setMobileSideNav,
    setHeaderSearchQuery,
}