import api from '@/services/api'
import { JSONrequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    consignments:[],
    consignmentDetails:null,
}

const mutations = {
  setCosignments(state,payload){
    state.consignments = payload.consignments
  },

  setConsignmentDetails(state,payload){
    state.consignmentDetails = payload.consignmentDetails
  }
}

const actions = {
    registerConsignment: ({ dispatch }, payload) => {

        const requestBody = {
          branch:payload.branch,
          reference:payload.reference,
          consigned_products:payload.consigned_products,
          description:payload.description,
        }
      
        return new Promise((resolve,reject)=>{
          api
          .post(`/products/consignment/register_consignment/`, requestBody, JSONrequestConfig( getToken() ))
          .then((response)=>{   
            dispatch({type:'loadConsignments',id:payload.branch}).then(()=>{
                resolve(response)
            })
          })
          .catch((error)=>{reject(error)}) 
        })
    },

      
    loadConsignments: ({ commit },payload) => {
        return new Promise((resolve,reject)=>{
          api
          .get(`/products/consignment/${payload.id}/branch_consignments/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            console.log(response)
              commit({type:'stopAuthenticationLoader'},{root:true})
              commit({type:'setCosignments',consignments:response.data})
              resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })
    },


    loadConsignmentInstance: ({ commit },payload) => {
        return new Promise((resolve,reject)=>{
          api
          .get(`/products/consignment/${payload.id}/get_consignment/
          `, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{root:true})
            commit({type:'setConsignmentDetails',consignmentDetails:response.data})
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}