import api from '@/services/api'
import { JSONrequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    expenses:[],
    expenseDetails:null,
}

const mutations = {
    setExpenses(state,payload){
        state.expenses = payload.expenses
    },
    
    setExpenseDetails(state,payload){
        state.expenseDetails = payload.expenseDetails
    }
}

const actions = {
    
    createExpense: ({ dispatch },payload) => {
        
        const requestBody = {
            branch:payload.branch,
            name:payload.name,
            amount: payload.amount,
            description: payload.description,
        }

        console.log(payload)
        
        return new Promise((resolve,reject)=>{
            api
            .post(`/expenses/expense/add_expense/`, requestBody ,JSONrequestConfig( getToken() ) )
            
            .then((response)=>{
                dispatch({type:'loadExpenses',id:payload.branch}).then(()=>{
                    resolve(response)
                })
            })
            .catch((error)=>{reject(error)}) 
        })

    },

    loadExpenses: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/expenses/expense/${payload.id}/branch_expenses/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({type:'setExpenses',expenses:response.data})
            resolve(response.data)
          })
          .catch((error)=>{reject(error.response)}) 
        })

    },


    loadExpenseInstance: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/expenses/expense/${payload.id}/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({type:'setExpenseDetails',expenseDetails:response.data})
            resolve(response.data)
          })
          .catch((error)=>{reject(error.response)}) 
        })

    }
    
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}