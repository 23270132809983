import api from '@/services/api'
import { JSONrequestConfig, formDataRequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
  orders:[],
  orderDetails:null,
  is_pending:null,
  loadingButton:null,
}

const mutations = {
  setOrders(state,payload){
    state.orders = payload.orders
  },

  setOrderDetails(state,payload){
    state.orderDetails = payload.orderDetails
  },
  setLoadingButton(state,payload){
    state.loadingButton = payload.loadingButton
  }
}

const actions = {
  loadOrderInstance: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/records/order/${payload.id}/get_order/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })

            commit({type:'setOrderDetails',orderDetails:response.data})
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })

    },

    updatePendingItemFalse({ dispatch },payload){
      return new Promise((resolve,reject)=>{
        api
        .put(`/records/order/${payload.itemId}/deactivate_pending_item/`, null , JSONrequestConfig( getToken() ) )
        .then((response)=>{
          dispatch({type:'loadOrderInstance',id:payload.id}).then(()=>{
            resolve(response)
          })
        })
        .catch((error)=>{reject(error)}) 
      })
    },

    updatePendingFalse({ dispatch },payload){
      return new Promise((resolve,reject)=>{
        api
        .put(`/records/order/${payload.id}/deactivate_pending_order/`, null , JSONrequestConfig( getToken() ) )
        .then((response)=>{
          dispatch({type:'loadOrderInstance',id:payload.id}).then(()=>{
            resolve(response)
          })
        })
        .catch((error)=>{reject(error)}) 
      })
    },

    deleteOrderItem({ dispatch },payload){
      console.log(payload.id)
      return new Promise((resolve,reject)=>{
        api
        .delete(`/records/order/${payload.itemId}/delete_order_item/
        `, JSONrequestConfig( getToken() ) )
        .then((response)=>{
          console.log('clicked delete')
          dispatch({type:'loadOrderInstance',id:payload.id}).then(()=>{
            resolve(response)
          })
        })
        .catch((error)=>{reject(error)}) 
      })
    },

    deleteOrderInstance(context,payload){
      console.log(JSONrequestConfig( getToken() ))
      return new Promise((resolve,reject)=>{
        api
        .delete(`/records/order/${payload.id}/delete_order/
        `, JSONrequestConfig( getToken() ) )
        .then((response)=>{
          console.log('clicked delete')
          resolve(response)
        })
        .catch((error)=>{reject(error)}) 
      })
    },


    loadOrders({ commit },payload){
      return new Promise((resolve,reject)=>{
        
        api
        .get(`/records/order/${payload.id}/branch_orders_filter/`, JSONrequestConfig( getToken() ) )
        .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{root: true})
            commit({type:'setOrders',orders:response.data.branch_orders})
            resolve(response.data.branch_orders)
        })
        .catch((error)=>{reject(error)}) 
      })
    },

    registerOrders({ dispatch },payload){

      let orderItems = payload.order_items
    
      for(const item in orderItems){
        orderItems[item].type = 'product'
      }
    
      const requestBody = {
        branch:payload.branch,
        name:payload.name,
        order_items:orderItems,
        client_phone:payload.client_phone,
        comment: payload.comment,
        type:0,
      }
    

      console.log(requestBody,'--order Request object')
      return new Promise((resolve,reject)=>{
        api
        .post(`/records/order/place_order/`, requestBody, JSONrequestConfig( getToken() ))
        .then((response)=>{
          dispatch({type:'loadOrders',id:payload.branch}).then(()=>{
            resolve(response)
          })
        })
        .catch((error)=>{reject(error)}) 
      })
    },

    sellOrder(context,payload){
      const requestBody = {

        amount_received:payload.amount_received,
        sale_payments:payload.sale_payments,
      }

      console.log(requestBody)
  
    return new Promise((resolve,reject)=>{
      api
      
      .put(`/records/sale/${payload.id}/make_sale/`, requestBody, JSONrequestConfig( getToken() ))
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{reject(error)}) 
      })
    },

    updateOrderInstance: ({dispatch},payload)=>{
      let form = new FormData()
      for(const key in payload){
        if (key!=='type' && key!=='branchId') {
          form.append(key, payload[key])
          console.log([key,payload[key]])
        }
      }
      
    
      return new Promise((resolve,reject)=>{
          api
          .put(`/records/order/${payload.id}/update_information/`,form, formDataRequestConfig( getToken() ) )
          .then((response)=>{
            dispatch({type:'loadOrderInstance',id:payload.id}).then(()=>{
              resolve(response)
            })
          } )
          .catch((error)=>{reject(error)}) 
      })
    },

    updateOrderItems: ({dispatch},payload)=>{

      const requestBody = {
        order_items:payload.order_items,
        comment: payload.comment,
      }
    
      

      return new Promise((resolve,reject)=>{
          api
          .put(`/records/order/${payload.id}/add_order_items/`,requestBody, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            dispatch({type:'loadOrderInstance',id:payload.id}).then(()=>{
              resolve(response)
            })
          } )
          .catch((error)=>{reject(error)}) 
      })
    },



    
}





export default {
    namespaced: true,
    state,
    mutations,
    actions,
}