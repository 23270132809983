<template>
<div>
  <apexchart :type="type" height="480" :options="options" :series="series"></apexchart>
</div>
</template>

<script>
export default {
    props:{
      type: String,
      dataset: Array,
    },
    data() {
    return {
      options: {
        chart:{
          toolbar: {
            show: true,
             tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset:false
            },
          },
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        },
        stroke: {
          curve: 'smooth',
          width:3,
        },
        dataLabels: {
          enabled: false
        },
        colors:['#FA7C29', '#057DCD', '#9C27B0'],
        fill:{
          colors: ['#FA7C29','#057DCD','#1E3D58']
        }
      },
      series: [{
        name: 'Montly Revenue',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      },{
        name: 'Montly Cost',
        data: [20, 30, 15, 40, 39, 30, 60, 41],
      }]
    }
  }
}
</script>