import Home from '@/views/Home.vue'
import { Security, Account, Billing, Metrices, UserRoles } from './Settings'
import { AllDetails } from './Details'


//BranchView Route Groups
import Branches from './BranchRoutes/Branches'
import Products from './BranchRoutes/products'
import Consignments from './BranchRoutes/Consignments'
import Orders from './BranchRoutes/Orders'
import Credits from './BranchRoutes/Credits'
import Expenses from './BranchRoutes/Expenses'
import Sales from './BranchRoutes/Sales'
import Staff from './BranchRoutes/Staff'
import Contacts from './BranchRoutes/Contacts'
import Analysis from './BranchRoutes/Analysis'
import Activity from './BranchRoutes/Activity'

console.log(Branches)

export const DashBoard = {
  path: '/dashboard',
  name: 'Home',
  component: Home,
  meta: {
    requiresVendorPermission:true,
    requiresAuth: true,
  },
}

export const BranchesView = {
  path: '/branches',
  meta: {
    requiresAuth: true,
    sideOptions: true,
  },
  component: () => import('../views/BranchPage.vue'),
  children:
    [
      Branches,
      Products,
      Orders,  
      Consignments,
      Credits,
      Expenses,
      Sales,
      Staff,
      Contacts,
      Analysis,
      Activity,
      ...AllDetails,
    ]
}

export const Settings = {
  path: '/settings',
  meta: {
    requiresAuth: true,
    requiresVendorPermission:true,
      
      TITLE_BAR_DATA:{
        name:{singular:'Settings',plural:'Settings'},
        buttons:[]
      },
  },
  component: () => import('../views/Settings.vue'),
  children:[
    Account,
    Security,
    Billing,
    Metrices,
    UserRoles,
  ],
}


