import { createStore } from 'vuex'
import Mutations from './mutations.js'
import Actions from './actions.js'
import products from './modules/products.js'
import credits from './modules/credits.js'
import expenses from './modules/expenses.js'
import sales from './modules/sales.js'
import consignments from './modules/consignments.js'
import orders from './modules/orders.js'
import branches from './modules/branches.js'
import contacts from './modules/contacts.js'
import staff from './modules/staff.js'
import analysis from './modules/analysis.js'
import activity from './modules/activity.js'
import userRoleSettings from './modules/settings_user_roles'

const defaultState = {
    loaderAuth: false,
    isAuthenticated: false,
    headerSearchQuery:"",
    mobileSideNav:true,
    error:false,
    user: {},
    branchId: null,
}

export default createStore({
  //state
  state:defaultState,
  //mutations
  mutations:{
    ...Mutations,
    resetState: state => Object.assign(state,defaultState)
  },
  actions:{
    ...Actions
  },

  modules: {
    products,
    credits,
    expenses,
    sales,
    consignments,
    orders,
    branches,
    contacts,
    staff,
    analysis,
    activity,
    userRoleSettings,
  }
  
})
