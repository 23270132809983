import api from '@/services/api'
import { JSONrequestConfig, formDataRequestConfig  ,getToken} from '@/helpers/userAuthentication';

const state = {
    branches:[],
    branchDetails:null,
    loadingBranches:false,
}

const mutations = {
    setBranches:(state,payload)=>{
        state.branches = payload.branches
    },
    setBranchDetails:  (state,payload)=>{
      state.branchDetails = payload.branchDetails
    },
    setBranchesLoadingState: (state,payload)=>{
        state.loadingBranches = payload.state
    }
}

const actions = {
    loadBranches:({ commit, rootState}) => {
        return new Promise((resolve,reject)=>{
          api
          .get(`/branches/branch/${rootState.user.username}/vendor_branches/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
              commit({type:'stopAuthenticationLoader'},{root:true})
              commit({type:'setBranches',branches:response.data})
              resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })
      },

      loadBranchInstance: ({ commit },payload)=>{
        return new Promise((resolve,reject)=>{
          api
          .get(`/branches/branch/${payload.id}/get_branch/
          `, JSONrequestConfig( getToken() ) )
          .then((response)=>{
              commit({type:'stopAuthenticationLoader'},{root:true})
              commit({type:'setBranchDetails',branchDetails:response.data})
              console.log(response.data)
              resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })
      },
      
      createBranch:({ dispatch,commit },payload) =>{
      
        let form = new FormData()
        // form.append('vendor',state.user.username)
        for(const key in payload){
          if (key!=='type' && key!=='branchId') {
            form.append(key, payload[key])
          }
        }

        for (var pair of form.entries()) {
          console.log(pair[0],'-', pair[1]);
        }
      
          commit({ type:'setBranchesLoadingState', state: true, })
          
          return new Promise((resolve,reject)=>{
              api
              .post(`/branches/branch/create_branch/`,form,formDataRequestConfig( getToken() ) )
              .then((response)=>{
                dispatch({
                  type:'loadBranches',
                }).then(()=>{
      
                commit({ type:'setBranchesLoadingState', state: false })
      
                  resolve(response)
                })
                
              } )
              .catch((error)=>{reject(error)}) 
          })
      },
      
      updateBranch:({dispatch},payload) =>{
        let data = payload
        let form = new FormData()  

        for(const key in data){
          if (key!=='type' && key!=='id') {
            form.append(key, payload[key])
            console.log([key,payload[key]])
          }
        }
        
      
        return new Promise((resolve,reject)=>{
            api
            .put(`/branches/branch/${payload.id}/branch_details/`,form, formDataRequestConfig( getToken() ) )
            .then((response)=>{
              dispatch({
                type:'loadBranches',
              })
              resolve(response)
            } )
            .catch((error)=>{reject(error)}) 
        })
      },
      
      
      deleteBranch:({ dispatch,commit },payload) =>{
          const requestBody = {
              password: payload.password
          }
      
          commit({ type:'setBranchesLoadingState', state: true })
      
          return new Promise((resolve,reject)=>{
              api
              .put(`/branches/branch/${payload.branchId}/delete_branch/`,requestBody, JSONrequestConfig( getToken() ) )
              .then((response)=>{
                dispatch({
                  type:'loadBranches',
                }).then(()=>{
                  commit({ type:'setBranchesLoadingState', state: false })
                  resolve(response)
                })
                
              } )
              .catch((error)=>{reject(error)}) 
          })
      }


}





export default {
    namespaced: true,
    state,
    mutations,
    actions,
}