export default{
    name: 'activity',
    path: 'activity/:id',
    meta: {
      index:9,
      requiresVendorPermission:true,
      OPTIONS:{sideSelector: true, state:{name:'activity',data:'activities'}}, 
      
      TITLE_BAR_DATA:{
        name:{singular:'Activity',plural:'Activity'},
        buttons:[]
      },
    },
    component: () => import('@/components/Activity/ActivityPage.vue'),
}

