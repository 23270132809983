import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApexCharts from "vue3-apexcharts"
import { initialize } from "@/helpers/initialize"
import Notifications from '@kyvg/vue3-notification'
import VCalendar from 'v-calendar'
import api from "@/services/api"

initialize(router,store);

const app = createApp(App)

app.config.globalProperties.$axios = api

app.use(VCalendar, {})
.use(store).use(router)
.use(VueApexCharts)
.use(Notifications)
.mount('#app')

