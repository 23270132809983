export default{
    path: '/sales/:id',
    name: 'sales',
    props: true, 
    meta: {
      index:6,
      requiresVendorPermission:true,
      OPTIONS:{sideSelector: true, state:{name:'sales',data:'sales'} },
      TITLE_BAR_DATA:{
        name:{singular:'sale',plural:'sales'},
        buttons:[]
      },
      REQUESTS:{load:'sales/loadSales'},
      RESPONSE_DATA_FILTER:{
        header:{name:'',amount_received:{substitute:'amount received'},attendant:'',created:''},
      }, 
    },
    component: () => import('@/components/List.vue'),
  }
  
 


 