// modals
const UserRolesModalAdd = {
    name: 'user-roles-add',
    path: 'add',
    meta:{
      M_TITLE:{modifier:'create',type: 'branch'},
      M_REQUESTS:{create:'userRoleSettings/createUserRole'},
      RENDER_INPUTS:{
        name: {type:'text',substitute:'name',validate:true},
        description: {type:'description',substitute:'description'}
      },
    },
  
    component: () => import('@/components/Modals/ModalContainer.vue')
  }
  
// end modals



export const Account = {
    name:'Settings',
    path:'account',
    component: () => import('../components/settings/sections/Account.vue')
}
  
export const Security = {
    path: 'security',
    component: () => import('../components/settings/sections/Security.vue')
} 

export const UserRoles = {
    name:'user-roles',
    path: 'user-roles',
    meta:{
        OPTIONS:{sideSelector: false, state:{name:'branches',data:'branches'}}, 
        TITLE_BAR_DATA:{
           
            name:{singular:'User Roles',plural:'User Role'},
            buttons:[{name:'user role',pathName:'user-roles-add'}]
        },
    },
    
    component: () => import('../components/settings/sections/UserRoles.vue'),
    children:[UserRolesModalAdd],
    
}
  
export const Billing = {
    path: 'billing',
    component: () => import('../components/settings/sections/Billing.vue')  
}
export const Metrices = {
    path: 'metrices',
    component: () => import('../components/settings/sections/Units.vue')  
  }