const CreditsModalAdd = {
  name: 'tabsAdd',
  path: 'add',
  meta:{
    M_TITLE:{modifier:'create',type: 'tab'},
    M_REQUESTS:{create:'credits/createTab'},
    RENDER_INPUTS:{
      name: {type:'text',substitute:'name',validate:true},description: {type:'description',substitute:'description'},
    },
  },

  component: () => import('@/components/Modals/ModalContainer.vue')
}

export default{
    path: '/credits/:id',
    name: 'credits',
    props: true,
    meta: {
      requiresVendorPermission:true,
      index:4,
      OPTIONS:{sideSelector: true, state:{name:'credits',data:'creditTabs'} },
      TITLE_BAR_DATA:{
        name:{singular:'credit & debit',plural:'credits & debits'},
        buttons:[{name:'tab',pathName:'tabsAdd',userRole:'can_create_account_tabs'}]
      },
      REQUESTS:{load:'credits/loadTabs'},
      RESPONSE_DATA_FILTER:{
        header:{name:'',total:'',description:'',modified:''},
      }, 
    },
    component: () => import('@/components/List.vue'),
    children:[CreditsModalAdd]
}
  


