const BranchesModalAdd = {
  name: 'BranchAdd',
  path: 'add',
  component: () => import('@/components/Branches/modals/ModalAddBranch.vue')
} 

const BranchesModalEdit = {
  name:'BranchEdit',
  path: 'edit/:subId',
  meta:{
    M_REQUESTS:{update:'branches/updateBranch'}
  },
  component: () => import('../../components/Branches/modals/ModalEditBranch.vue')
}

export default{
    name: 'Branches',
    path: '',
    meta: {
      index:0,
      requiresVendorPermission:true,
      OPTIONS:{sideSelector: false, state:{name:'branches',data:'branches'}}, 
      
      TITLE_BAR_DATA:{
        name:{singular:'Branch',plural:'Branches'},
        buttons:[{name:'branch',pathName:'BranchAdd'}]
      },
    },
    component: () => import('../../components/Branches/List.vue'),
    children:[BranchesModalAdd,BranchesModalEdit]
}

