import api from '@/services/api'
import { JSONrequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    activities:[],
}

const mutations = {
    setActivities(state,payload){
        state.activities = payload.activities
      },

}

const actions = { 

    loadActivities: ({commit},payload) => {
        let cursor = payload.cursor ? `?cursor=${payload.cursor}` : ''
        return new Promise((resolve,reject)=>{
          api
          .get(`/branches/activity_log/${payload.id}/branch_activity_log/${cursor}
          `, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({type:'setActivities',activities:response.data.results})
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        }) 
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}