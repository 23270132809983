const mainSection = {
  name: 'analysis',
  path: '',
  component: () => import('@/components/Analysis/Main.vue')
} 

// const kpiSection = {
//   name:'anlysis-kpi',
//   path: 'kpi',
//   component: () => import('../../components/Branches/modals/ModalEditBranch.vue')
// }

const transactionsSection = {
  name:'transactions',
  path:'transactions',
  meta:{
    TITLE_BAR_DATA:{
      name:{singular:'transactions',plural:'transactions'},
      buttons:[{type:'date',name:'date'},]
    },
    OPTIONS:{ sideSelector: true,sideDateSelector:true , state:{name:'analysis',data:'analysis'} },
  },
  component: () => import('@/components/Analysis/Transactions.vue')
}

const productSummarySection = {
  name:'product-summary',
  path:'product_summary',
  meta:{
    TITLE_BAR_DATA:{
      name:{singular:'product summary',plural:'product summary'},
      buttons:[{type:'date',name:'date'},]
    },
    OPTIONS:{ sideSelector: true,sideDateSelector:true , state:{name:'analysis',data:'analysis'} },
    RESPONSE_DATA_FILTER:{
      header:{
        name:'',
        price:{substitute:'price(GHC)'},
        orders:'',
        sales:'',
        total:'',
      }
    }, 
  },
  component: () => import('@/components/Analysis/ProductSummary.vue')
}


export default{
    path: '/analysis/:id',
    props: true, 
    meta: {
      requiresVendorPermission:true,
      sideOptions: false,
      index:9,
      OPTIONS:{sideSelector: true, state:{name:'analysis',data:'analysis'} },
      TITLE_BAR_DATA:{
        name:{singular:'analysis',plural:'analysis'},
        buttons:[]
      },
    },
    component: () => import('@/components/Analysis/Analysis.vue'),
    children:[mainSection,transactionsSection,productSummarySection]
}
  
 
 

