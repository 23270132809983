const ContactsModalAdd = {
  name: 'contactsAdd',
  path: 'add',
  meta:{
    M_TITLE:{modifier:'create',type: 'contacts'},
    M_REQUESTS:{create:'contacts/createContact'},
    RENDER_INPUTS:{
      photo:{type:'image'},name: {type:'text',substitute:'name',validate:true},
      phone: {type:'text',substitute:'phone',isPhone:true,validate:true},
      email: {type:'text',substitute:'email'}
    },
  },

  component: () => import('@/components/Modals/ModalContainer.vue')
}

export default{
    path: '/contacts/:id',
    name: 'contacts',
    props: true, 
    meta: {
      index:8,
      requiresVendorPermission:true,
      OPTIONS:{sideSelector: true, state:{name:'contacts',data:'contacts'} },
      TITLE_BAR_DATA:{
        name:{singular:'contact',plural:'contacts'},
        buttons:[{name:'contact',pathName:'contactsAdd',userRole:'can_create_contacts'},]
      },
      REQUESTS:{load:'contacts/loadContacts'},
      RESPONSE_DATA_FILTER:{
        header:{name:'',phone:'',email:''},
      }, 
    },
    component: () => import('@/components/List.vue'),
    children:[ContactsModalAdd]
  }
  
 


