import api from '@/services/api'
import { JSONrequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    sales:[],
    saleDetails:null,
}

const mutations = {
    setSales(state,payload){ 
        state.sales = payload.sales
      },
    
      setSaleDetails(state,payload){
        state.saleDetails = payload.saleDetails
      }
}

const actions = {

    loadSales: ({state,commit},payload) => {
        let next = payload.next ? `?cursor=${payload.next}` : ''
        let salesData 

        console.log(next,"--next in sales")

        return new Promise((resolve,reject)=>{
          api       
          .get(`/records/sale/${payload.id}/branch_sales_cursor_pagination/${next}`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            
            commit({type:'stopAuthenticationLoader'},{ root: true })
            
            salesData = response.data.results
            if (payload.next) salesData = state.sales.concat(salesData)

            commit({type:'setSales',sales:salesData})
            console.log(state.sales)
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })

    },

    loadSaleInstance: ({commit},payload) => {

      return new Promise((resolve,reject)=>{
        api
        .get(`/records/sale/${payload.id}/
        `, JSONrequestConfig( getToken() ) )
        .then((response)=>{
          commit({type:'stopAuthenticationLoader'},{ root: true })
          commit({type:'setSaleDetails',saleDetails:response.data})
          resolve(response.data)
        })
        .catch((error)=>{reject(error)}) 
      })

  },
     
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}