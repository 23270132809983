import api from '@/services/api'
import { JSONrequestConfig,formDataRequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    contacts:[],
    contactDetails:null,
}

const mutations = {
    setContacts(state,payload){
        state.contacts = payload.contacts
      },
    
      setContactDetails(state,payload){
        state.contactDetails = payload.contactDetails
      }
}

const actions = {
  createContact: ({dispatch},payload) => {

    let form = new FormData()
      // form.append('vendor',state.user.username)
      console.log(payload)
      for(const key in payload){
        if (key!=='type') {
          form.append(key, payload[key])
        }
    }
    
    return new Promise((resolve,reject)=>{
        api
        .post(`/contacts/contact/create_contact/`, form,formDataRequestConfig( getToken() ) )
        
        .then((response)=>{
          dispatch({type:'loadContacts',id:payload.branch}).then(()=>{
            resolve(response)
          })  
        })
        .catch((error)=>{reject(error)}) 
    })

  },

    loadContacts: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/contacts/contact/${payload.id}/branch_contacts/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({type:'setContacts',contacts:response.data.branch_contacts })
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })

    },
     
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}