import api from '@/services/api'
import { JSONrequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    creditTabs:[],
    creditTabDetails:null,
    recordDetails:null,
}

const mutations = {
    setCreditTabs(state,payload){
        state.creditTabs = payload.creditTabs
      },
    
      setCreditTabDetails(state,payload){
        state.creditTabDetails = payload.creditTabDetails
      },
      setRecordDetails(state,payload){
        state.recordDetails = payload.recordDetails
      },
}

const actions = {
    
    createTab: ({dispatch},payload) => {
        
        const requestBody = {
            branch:payload.branch,
            name:payload.name,
            description: payload.description,
        }
        
        return new Promise((resolve,reject)=>{
            api
            .post(`/records/credit/create_tab/`, requestBody ,JSONrequestConfig( getToken() ) )
            
            .then((response)=>{
                dispatch({
                    type:'loadTabs',
                    id:payload.branch,
                }).then(()=>{
                    resolve(response)
                })   
                
            })
            .catch((error)=>{reject(error)}) 
        })

    },

    loadTabs: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/records/credit/${payload.id}/branch_credit_tabs/
          `, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({type:'setCreditTabs',creditTabs:response.data})
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        }) 

    },
    loadTabInstance: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/records/credit/${payload.id}/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({type:'setCreditTabDetails',creditTabDetails:response.data})
            resolve(response.data)
          })
          .catch((error)=>{reject(error.response.data)}) 
        })

    },

    loadRecordInstance: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/records/credit/${payload.id}/get_record/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({type:'setRecordDetails',recordDetails:response.data})
            resolve(response.data)
          })
          .catch((error)=>{reject(error.response.data)}) 
        })

    },

    updateCreditTab(context,payload){
        

        const requestBody = {
            order:payload.orderId
        }

        console.log(requestBody)
        
        return new Promise((resolve,reject)=>{
            api
            .put(`/records/credit/${payload.creditTabId}/credit_order/`, requestBody ,JSONrequestConfig( getToken() ) )
            
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{reject(error)}) 
        })


    },

    addCreditRecord({dispatch},payload){
        const requestBody = {
            value:payload.value,
            description: payload.description,
            record_type: 0,
        }
        console.log(payload.branch)
        
        return new Promise((resolve,reject)=>{
            api
            .put(`/records/credit/${payload.branch}/add_record/`, requestBody ,JSONrequestConfig( getToken() ) )
            
            .then((response)=>{
                dispatch({type:'loadTabInstance',id:payload.branch}).then(()=>{
                    resolve(response)
                })
            })
            .catch((error)=>{reject(error)}) 
        })

    },
    addDebitRecord({ dispatch },payload){
        const requestBody = {
            value:payload.value,
            description: payload.description,
            record_type: 1,
        }

        console.log(requestBody)
        
        return new Promise((resolve,reject)=>{
            api
            .put(`/records/credit/${payload.branch}/add_record/`, requestBody ,JSONrequestConfig( getToken() ) )
            
            .then((response)=>{
                dispatch({type:'loadTabInstance',id:payload.branch}).then(()=>{
                    resolve(response)
                })
            })
            .catch((error)=>{reject(error)})
        })

    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}