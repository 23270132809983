import api from '@/services/api'
import { JSONrequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    transactions:{},
    productSummary:[],
    transactionsDateTimeRange:{}
}

const mutations = {
    setTransactions:(state,payload)=>{
        state.transactions = payload.transactions
    },

    setProductSummary:(state,payload)=>{
      state.productSummary = payload.productSummary
    },

    setTransactionsDateTimeRange:(state,payload)=>{
        state.transactionsDateTimeRange = payload.transactionsDateTimeRange
    },
}

const actions = {
  loadTransactions:({state,commit},payload) => {

    let requestBody = state.transactionsDateTimeRange
    console.log(requestBody)

    return new Promise((resolve,reject)=>{
        api
        .put(`/transactions/transaction/${payload.id}/transactionsnrecords/`,requestBody, JSONrequestConfig( getToken() ) )
        .then((response)=>{
          commit({
            type:'setTransactions',
            transactions: response.data,
          })
          commit({type:'stopAuthenticationLoader'},{root:true})
          resolve(response)
        } )
        .catch((error)=>{reject(error)}) 
    })
  },

  loadProductSummary:({state,commit},payload) => {

    let requestBody = state.transactionsDateTimeRange

    return new Promise((resolve,reject)=>{
        api
        .put(`/products/category_product/${payload.id}/products_summary_timeline/`,requestBody, JSONrequestConfig( getToken() ) )
        .then((response)=>{
          commit({
            type:'setProductSummary',
            productSummary: response.data,
          })
          commit({type:'stopAuthenticationLoader'},{root:true})
          resolve(response)
        } )
        .catch((error)=>{reject(error)}) 
    })
  },

}





export default {
    namespaced: true,
    state,
    mutations,
    actions,
}