let links = [
    {name:'Product-Category',view:'can_view_products'},
    {name:'consignments',view:'can_view_consignments'},
    {name:'orders',view:'can_view_orders'},
    {name:'sales',view:'can_view_sales'},
    {name:'credits',view:'can_view_account_tabs'},
    {name:'expenses',view:'can_view_expenses'},
    {name:'staff',view:'can_view_staff'},
    {name:'contacts',view:'can_view_contacts'},
    {name:'analysis',view:'can_view_analyses'},
    {name:'Home',view:'can_view_dashboard'},
    {name:'Branches',view:'can_view_branches'},
]

export function initialize(router,store) {
    

    function nextWithPrivileges(to,next){
        let serverUserRoles = store.state.user.user_role
        store.commit({type:'setHeaderSearchQuery',headerSearchQuery:""})
        let userRoles = {
            ...serverUserRoles,
            can_view_dashboard: false,
            can_view_branches: false,
        }

        let unviewableRoles = [];
        let firstviewbleRole = null

        for(let link of links){
            if ( !userRoles[link.view] ){
                unviewableRoles.push(link.name)
            }else if( !firstviewbleRole ){
                firstviewbleRole = link.name
            }
            
        }
        
        if (!serverUserRoles || !unviewableRoles.includes(to.name)) next()
        else{
            store.commit({type:'setBranchId', branchId:store.state.user.native_branch})
            next({name:firstviewbleRole,params:{id: store.state.user.native_branch} }) 
        }
        
    }


    router.beforeEach((to, from, next) => {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            const isAuthenticated = store.state.isAuthenticated;
            // const requiresVendorPermission = to.meta.requiresVendorPermission
            // let vendorPermission = store.state.user.user_type === "Vendor"
            if (!isAuthenticated) {
                store.dispatch({type:'AuthenticateToken'})
                .then(() =>{
                    nextWithPrivileges(to,next)
                })
                .catch(()=>{
                    next({name: 'Login'})
                })
            }

            else{
                nextWithPrivileges(to,next)
            }
        }
        else {
            next() // make sure to always call next()!
        }
        
    })
}