import api from '@/services/api'
import { JSONrequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
  newRole: false,
  userRoles: null,
}

const mutations = {
  setUserRoles(state,payload){
    state.userRoles = payload.userRoles
  },
  setNewRole(state,payload){
    state.newRole = payload.newRole
  }
}

const actions = {
  createUserRole: ({dispatch,commit},payload) => {
    

    let requestBody = {
        "name": payload.name,
        "description":payload.description,
        "can_view_products": false,
        "can_create_products": false,
        "can_manage_products": false,
        "can_view_consignments": false,
        "can_create_consignments": false,
        "can_manage_consignments": false,
        "can_view_orders": false,
        "can_create_orders": false,
        "can_manage_orders": false,
        "can_view_sales": false,
        "can_make_sales": false,
        "can_refund_sales": false,
        "can_view_account_tabs": false,
        "can_create_account_tabs": false,
        "can_manage_account_tabs": false,
        "can_view_expenses": false,
        "can_create_expenses": false,
        "can_manage_expenses": false,
        "can_view_staff": false,
        "can_create_staff": false,
        "can_manage_staff": false,
        "can_view_contacts": false,
        "can_create_contacts": false,
        "can_manage_contacts": false,
        "can_view_analyses": false,
        "can_view_activity_log": false,
        "can_manage_activity_log": false,
        "can_view_other_settings": false,
        "can_manage_other_settings": false,

    }


    return new Promise((resolve,reject)=>{
        api
        .post(`/users/user_role/create_user_role/`, requestBody,JSONrequestConfig( getToken() ) )
        
        .then((response)=>{
            commit({
              type:'setNewRole',
              newRole:true,
            })
            dispatch({
              type:'loadUserRoles'
            }).then(()=>{
              resolve(response)
            }) 
        })
        .catch((error)=>{reject(error)}) 
    })

  },
  loadUserRoles: ({commit}) => {
  
        return new Promise((resolve,reject)=>{
          api.get(`/users/user_role/vendor_user_roles/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            
            commit({
              type: 'setUserRoles',
              userRoles: response.data,
            })
            commit({type:'stopAuthenticationLoader'},{ root: true })

            resolve(response)
          })
          .catch((error)=>{reject(error)}) 
        })

    },
    updateUserRole:({dispatch},payload) =>{
     let requestBody = payload.userRoleObject

     console.log(payload.id)
    
      return new Promise((resolve,reject)=>{
          api      
          .put(`/users/user_role/${payload.id}/update_user_role/`,requestBody, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            dispatch({
              type:'loadUserRoles'
            }).then(()=>{
              resolve(response)
            })  
          } )
          .catch((error)=>{reject(error)}) 
      })
    },
    deleteUserRole: ({dispatch},payload) => {

      console.log(JSONrequestConfig( getToken() ),'--test')
      return new Promise((resolve,reject)=>{
          api.delete(`/users/user_role/${payload.id}/delete_user_role/`, JSONrequestConfig( getToken() ) )
          
          .then((response)=>{
              dispatch({
                type:'loadUserRoles'
              }).then(()=>{
                resolve(response)
              })    
          })
          .catch((error)=>{reject(error)}) 
      })
  
    },
    
     
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}