<template>
 <div class="side-nav" :class="{'side-nav__mobile-hide':mobileSideNav}">
  <div class="side-nav__overlay" @click="setMobileSideNav">
  </div>
  <div class="side-nav__container">
    <options-card />
  </div>
   
  </div>
</template>

<script>
import OptionsCard from '@/components/Dashboard/SideCardOptions.vue'
export default {
  name: 'Dashboard',
  components:{ 
    OptionsCard,
  },
  computed:{
    mobileSideNav(){
      return this.$store.state.mobileSideNav
    }
  },
  methods:{
    setMobileSideNav(){
      this.$store.commit({type:'setMobileSideNav',mobileSideNav:true})
    }
  }
}
</script>

  

