import api from '@/services/api'
import { JSONrequestConfig, formDataRequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    categories:[],
    categoryDetails:null,
    productDetails:null,
    productHistory:null,
    isRoot:null,
    isEnd:null,
}

const mutations = {

    setCategories:(state,payload)=>{
        state.categories = payload.categories
    },
    setRootAndEnd:(state,payload)=>{
        state.isRoot = payload.isRoot
        state.isEnd = payload.isEnd
    },

    setProductDetails:(state,payload)=>{
        state.productDetails = payload.productDetails
    },

    setProductHistory:(state,payload)=>{
      state.productHistory = payload.productHistory
  },

    setCategoryDetails:(state,payload)=>{
        state.categoryDetails = payload.categoryDetails
    },


}

const actions = {
    
    createCategory: ({ dispatch },payload) => {
        let form = new FormData()
        for(const key in payload){
            if (key!=='type') {
                console.log(payload[key],key)
                form.append(key, payload[key])
            }
        }

        return new Promise((resolve,reject)=>{
            api
            .post(`/products/category/create_category/`, form ,formDataRequestConfig( getToken() ) )
            .then((response)=>{
                dispatch({
                    type:'loadCategories',
                    categoryId: payload.category_id,
                })
                .then(()=>{
                    resolve(response)
                })
            })
            .catch((error)=>{reject(error)}) 
        })

    },

    createProduct: ({ dispatch },payload) => {
        let form = new FormData()
        for(const key in payload){
            if (key!=='type') {
                form.append(key, payload[key])
            }
        }

        return new Promise((resolve,reject)=>{
            api
            .post(`/products/category_product/create_product/`, form ,formDataRequestConfig( getToken() ) )
            .then((response)=>{
                dispatch({
                    type:'loadCategories',
                    categoryId: payload.category,
                })
                .then(()=>{
                    resolve(response)
                })
            })
            .catch((error)=>{reject(error)}) 
        })

    },



    loadAllProducts: ({commit},payload) => {
      
        return new Promise((resolve,reject)=>{
          api
          .get(`/products/category_product/${payload.id}/branch_products/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({
                type:'setCategories',
                categories: response.data
            })
            commit({
                type:'setRootAndEnd',
                isRoot: false,
                isEnd: true,
            })
            console.log(response.data,'--payload')

            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })

    },


    loadCategories: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/products/category/${payload.categoryId}/get_category/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            commit({
                type:'setCategories',
                categories: response.data.sub_items,
            })
            commit({
                type:'setRootAndEnd',
                isRoot: response.data.is_root,
                isEnd: response.data.is_end,
            })
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })

    },

    loadProductInstance: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/products/category_product/${payload.id}/get_product/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })
            console.log(response.data)
            commit({
                type:'setProductDetails',
                productDetails: response.data,
            })
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })

    },

    loadProductHistory: ({commit},payload) => {

      return new Promise((resolve,reject)=>{
        api
        .get(`/products/category_product/${payload.id}/get_product_history/`, JSONrequestConfig( getToken() ) )
        .then((response)=>{
          console.log(response.data)
          commit({
              type:'setProductHistory',
              productHistory: response.data,
          })
          resolve(response.data)
        })
        .catch((error)=>{reject(error)}) 
      })

  },




    updateProductInstance: (context,payload)=>{
        let form = new FormData()
        for(const key in payload){
          if (key!=='type' && key!=='id') {
            form.append(key, payload[key])
            console.log([key,payload[key]])
          }
        }
        
      
        return new Promise((resolve,reject)=>{
            api
            .put(`/products/category_product/${payload.id}/product_details/`,form, formDataRequestConfig( getToken() ) )
            .then((response)=>{
              resolve(response)
            } )
            .catch((error)=>{reject(error)}) 
        })
    },

    loadCategoryInstance: ({commit},payload) => {

        return new Promise((resolve,reject)=>{
          api
          .get(`/products/category/${payload.id}/get_category/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            commit({type:'stopAuthenticationLoader'},{ root: true })

            commit({
                type:'setCategoryDetails',
                categoryDetails: response.data,
            })
            resolve(response.data)
          })
          .catch((error)=>{reject(error)}) 
        })

    },

    deleteCategoryInstance: ({dispatch},payload)=>{
        let form = new FormData()
        for(const key in payload){
          if (key!=='type' && key!=='id' && key !== 'subId') {
            form.append(key, payload[key])
            console.log([key,payload[key]])
          }
        }
        
      
        return new Promise((resolve,reject)=>{
            api
            .put(`/products/category/${payload.subId}/delete_category/`,form, formDataRequestConfig( getToken() ) )
            .then((response)=>{
              dispatch({
                type:'loadCategories',
                categoryId: payload.id,
              })
              .then(()=>{
                resolve(response)
              })
            } )
            .catch((error)=>{reject(error)}) 
        })
    },

    deleteProductInstance(context,payload){
        return new Promise((resolve,reject)=>{
        console.log(JSONrequestConfig( getToken() ))
          api
          .delete(`/products/category_product/${payload.id}/delete_product/`, JSONrequestConfig( getToken() ) )
          .then((response)=>{
            resolve(response)
          })
          .catch((error)=>{reject(error)}) 
        })
      },
  

    updateCategorytInstance: ({dispatch},payload)=>{
        let form = new FormData()
        for(const key in payload){
          if (key!=='type' && 
              key!=='id' &&  
              key!=='logo' &&
              key!== 'subId') { 
            form.append(key, payload[key])
            console.log([key,payload[key]])
          }else if(key==='logo'){
            form.append('image', payload[key])
          }
        }
        console.log(payload.id)
        
      
        return new Promise((resolve,reject)=>{
            api
            .put(`/products/category/${payload.subId}/category_details/`,form, formDataRequestConfig( getToken() ) )
            .then((response)=>{
              dispatch({
                type:'loadCategories',
                categoryId: payload.id,
              })
              .then(()=>{
                resolve(response)
              })
            })
            .catch((error)=>{reject(error)}) 
        })
    },



    
    
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}