<template>
  <router-view/>
  <div v-if="loaderAuth" class="auth-loader"><img class="auth-loader__image" src="@/assets/images/loader-logo.jpg" alt=""></div>
  <notifications classes="my-notification" :duration="2000" position="right top" />
  <notifications  classes="my-notification" :duration="12000" position="right top" group="network"/>
</template>

<script>
import '@/assets/css/reset.css'
import '@/assets/css/main.css'
import '@/assets/css/loaders.css'
export default {
  computed:{
    loaderAuth(){
      return this.$store.state.loaderAuth
    }
  },
  mounted(){
    this.$axios.interceptors.response.use(
    response => {
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },error => {
      if(error.response){
        if(error.response.status) {
          switch (error.response.status) {
            case 400:
            
            //do something
              break;
          
            case 401:
              console.log(this.$route.name)
              if (this.$route.name !== 'Login') {
                this.$notify({
                  title: "Authentications",
                  text: "Please Log in to continue",
                  type:"error"
                })
              }
              this.$router.push({name:'Login'})
              
              break;
            case 404:{
  
                this.$notify({
                  title: "Data Not Found",
                  text: "The Data You Requesting Does not exist",
                  type:"error"
                })
                
                this.$store.commit({type:'stopAuthenticationLoaderWithError'})
              }
                      
              break;               
              case 502:
            setTimeout(() => {
                console.log('timeout')
              }, 10000)
          }
          return Promise.reject(error)
        }
      }else{
        this.$notify({
          group:'network',
          title:"Network Error",
          text: "Please Check Your Network",
          type:"error",
        })
        return Promise.reject(error);
      }
    
    }

  )
  }
}
</script>

<style>
  .my-notification {
    
    margin:0.5em;
    padding:1em;
    border-radius: var(--border-radius);
  }

 /* style for title line */
  .notification-title {
    font-weight: bold;
    margin-bottom: .5em;
  }

  /*style for content */
  .notification-content {
    /*...*/
    
    font-size: 1.25em;
    font-size: .75em;
  }

  /* // additional styling hook when using`type` parameter, i.e. this.$notify({ type: 'success', message: 'Yay!' }) */
  .my-notification.success {
    background:#10B981 ;
    color:#A7F3D0;
    
    /*...*/
  }
  .my-notification.info {
    /*...*/
  }
  .my-notification.error {
    /*...*/
    color:#FECACA;
    background:#EF4444;
  }
</style>