<template>
    <label class="side-nav__select-label" for="timeline"> {{ name }}:</label>
	<div class="side-nav__select-container">
        <select class="side-nav__select" :ref="name" :name="name" id="timeline">
            <option  v-for="(option,index) in options" :value="option"  :key="index">{{ option }}</option>
        </select>
    </div>
</template>
<script>
export default {
    props:{
        name: String,
        options: Array,
    },
}
</script>

