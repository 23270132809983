const ExpensesModalAdd = {
  name: 'expenseAdd',
  path: 'add',
  meta:{
    M_TITLE:{modifier:'create',type: 'Expense'},
    M_REQUESTS:{create:'expenses/createExpense'},
    RENDER_INPUTS:{
      name: {type:'text',substitute:'name',validate:true},
      amount: {type:'text',substitute:'amount',validate:true},
      description: {type:'description',substitute:'description'},
    },
  },
  component: () => import('@/components/Modals/ModalContainer.vue')
}

export default {
    path: '/expenses/:id',
    name: 'expenses',
    props: true,
    meta: {
      index:5,
      requiresVendorPermission:true,
      OPTIONS:{sideSelector: true, state:{name:'expenses',data:'expenses'}},     
      TITLE_BAR_DATA:{
        name:{singular:'expense',plural:'expenses'},
        buttons:[{name:'expense',pathName:'expenseAdd',userRole:'can_create_expenses'}]
      },
      REQUESTS:{load:'expenses/loadExpenses'},
      RESPONSE_DATA_FILTER:{
        header:{
          name:"",
          amount:"",
          description:"",
        }
      }, 

    },
    
    component: () => import('@/components/List.vue'),
    children:[ExpensesModalAdd]
}

