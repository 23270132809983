const ProductCategoryModalAdd = {
  name: 'CategoryAdd',
  path: 'add',
  component: () => import('@/components/Modals/ModalAddProduct.vue')
}

const ProductCategoryModalEdit = {
  name:'CategoryEdit',
  path: 'edit/:subId',
  meta:{
    M_REQUESTS:{update:'products/updateCategorytInstance'}
  },
  component: () => import('@/components/Modals/ModalEditCategory.vue')
}

export  default{
    path: '/products/:id',
    name: 'Product-Category',
    props:true,
    meta: {
      index:1,
      requiresVendorPermission:true,
      OPTIONS:{sideSelector: true, state:{name:'products',data:'products',} },
      products: true,
      TITLE_BAR_DATA:{
        search:true,
        name:{singular:'Product',plural:'Products'},
        buttons:[{name:'Product',pathName:'CategoryAdd',userRole:'can_create_products'},{name:'Category',pathName:'CategoryAdd',userRole:'can_create_products'}]
      },
    },
    component: () => import('@/components/products/List.vue'),
    children:[ProductCategoryModalAdd, ProductCategoryModalEdit]
}

