import api from '@/services/api'
import { JSONrequestConfig, formDataRequestConfig, getToken} from '@/helpers/userAuthentication';

const state = {
    staff:[],
    staffDetails:null,
}

const mutations = {
    setStaff(state,payload){
        state.staff = payload.staff
      },
    
      setStaffDetails(state,payload){
        state.staffDetails = payload.staffDetails
      }
}

const actions = {
  createStaff: ({dispatch},payload) => {
    console.log(payload,"--paylaod")
    let form = new FormData()
      // form.append('vendor',state.user.username)
    for(const index in payload){
      if (index!=='type') {
          let key = (key === 'id') ? 'branch' : index
          form.append(key, payload[key])
      }
    }
    
    return new Promise((resolve,reject)=>{
        api
        .post(`/staff/staff/create_staff/`,form,formDataRequestConfig( getToken() ) )
        
        .then((response)=>{
            dispatch({type:'loadStaff',id:payload.branch}).then(()=>{
              resolve(response)
            })
            
        })
        .catch((error)=>{reject(error)}) 
    })

  },

  loadStaffUserRoles: () => {

    return new Promise((resolve,reject)=>{
      api
      .get(`/users/user_role/vendor_user_roles_only/`, JSONrequestConfig( getToken() ) )
      .then((response)=>{
        resolve(response.data)
      })
      .catch((error)=>{reject(error)}) 
    })

  },

  loadStaff: ({commit},payload) => {

    return new Promise((resolve,reject)=>{
      api
      .get(`/staff/staff/${payload.id}/branch_staff/`, JSONrequestConfig( getToken() ) )
      .then((response)=>{
        commit({type:'stopAuthenticationLoader'},{ root: true })
        commit({type:'setStaff',staff:response.data.branch_staff_s})
        resolve(response.data)
      })
      .catch((error)=>{reject(error)}) 
    })

  },

  loadStaffInstance: ({commit},payload) => {

    return new Promise((resolve,reject)=>{
      api
      .get(`staff/staff/${payload.id}/get_staff/`, JSONrequestConfig( getToken() ) )
      .then((response)=>{
        commit({type:'stopAuthenticationLoader'},{ root: true })
        console.log(response.data)
        commit({type:'setStaffDetails',staffDetails:response.data})
        resolve(response.data)
      })
      .catch((error)=>{reject(error)}) 
    })

  },

  updateStaffInstance: ({rootState},payload)=>{
    
    let form = new FormData()
    for(const key in payload){
      if (key!=='type' && key!=='id') {
        form.append(key, payload[key])
      }
    }
    
    form.append('vendor_username', rootState.user.native_vendor)
  
   

    return new Promise((resolve,reject)=>{
        api
        
        .put(`/staff/staff/${payload.id}/staff_details/`,form, formDataRequestConfig( getToken() ) )
        .then((response)=>{
          resolve(response)
        } )
        .catch((error)=>{reject(error)}) 
    })
  },

  deleteStaffInstance(context,payload){
    return new Promise((resolve,reject)=>{
      api
      .delete(`/staff/staff/${payload.id}/delete_staff/`, JSONrequestConfig( getToken() ) )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{reject(error)}) 
    })
  },

     
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}