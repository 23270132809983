const StaffModalAdd = {
  name: 'staffAdd',
  path: 'add',
  meta:{
    M_TITLE:{modifier:'create',type: 'staff'},
    M_REQUESTS:{create:'staff/createStaff',loadInputOptions:'staff/loadStaffUserRoles'},
    RENDER_INPUTS:{
      photo:{type:'image'}, name: {type:'text',substitute:'name*',validate:true},
      username: {type:'text',substitute:'username',validate:true},
      phone: {type:'text',substitute:'phone',isPhone:true,validate:true},
      user_role: {type:'select',substitute:'user roles',loadOptions:true,validate:true},
      description: {type:'description',substitute:'description'}
    },
  },

  component: () => import('@/components/Modals/ModalContainer.vue')
}

export default{
    path: '/staff/:id',
    name: 'staff',
    props: true, 
    meta: {
      index:7,
      requiresVendorPermission:true,
      OPTIONS:{sideSelector: true, state:{name:'staff',data:'staff'} },
      TITLE_BAR_DATA:{
        name:{singular:'staff',plural:'staff'},
        buttons:[{name:'staff',pathName:'staffAdd',userRole:'can_create_staff'},]
      },
      REQUESTS:{load:'staff/loadStaff'},
      RESPONSE_DATA_FILTER:{
        header:{name:'',phone:'',username:''},
      }, 
    },
    component: () => import('@/components/List.vue'),
    children:[StaffModalAdd]
  }
  
 
 

