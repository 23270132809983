export const ModalEdit = {
    productDetails:{
      name:'ProductEdit',
      path: 'edit',
      meta:{
        M_TITLE:{modifier:'edit',type: 'consignment'},
        M_REQUESTS:{load:'products/loadProductInstance',update:'products/updateProductInstance',delete:"products/deleteProductInstance"},
        RENDER_INPUTS:{
          name: {type:'text',substitute:'name'},
          batch_code: {type:'text',substitute:'batch code'},
          unit_cost_price:{type:'text',substitute:'cost price (per unit)'},
          unit_minimum_selling_price: {type:'text',substitute:'name'},
          unit_selling_price: {type:'text',substitute:'name'},
          auto_track_consignment: {type:'check',substitute:'track consignment'},
          description:{type:'description',substitute:'description'}
        },
        type:'product',
        modifier:'edit',
      },
      component: () => import('../components/Modals/ModalContainer.vue')
    },

    orderDetails:{
      name:'OrderEdit',
      path: 'edit',
      meta:{
        M_TITLE:{modifier:'edit',type: 'order'},
        M_REQUESTS:{load:'orders/loadOrderInstance',update:'orders/updateOrderInstance',delete:"orders/deleteOrderInstance"},
        RENDER_INPUTS:{
          name: {type:'text',substitute:'name'},
          client_phone: {type:"text",isPhone:true,substitute:"client's contact",},
          service_charge: {type:'fixed-select',substitute:'service charge'},
          discount_is_percentage:{type:'check',substitute:"percentage(%) discount",},  
          discount:{type:"text",substitute:"discount",},
          
        },     
      },
      component: () => import('../components/Modals/ModalContainer.vue')
    },
    staffDetails:{
      name:'staffEdit',
      path: 'edit',
      meta:{
        M_TITLE:{modifier:'edit',type: 'staff'},
        M_REQUESTS:{load:'staff/loadStaffInstance',update:'staff/updateStaffInstance',delete:"staff/deleteStaffInstance"},
        RENDER_INPUTS:{
          name: {type:'text',substitute:'name'},
          username: {type:'text',substitute:'username'},
          user_role: {type:'select',substitute:'user role',options:'vendor_user_roles'},
          phone: {type:'text',substitute:'phone',isPhone:true},
          description: {type:'description',substitute:''}, 
          is_active: {type:'check',substitute:'is active'},
        },     
      },
      component: () => import('../components/Modals/ModalContainer.vue')
    }
}


export const ModalListUpdate = {
  orders:{
    name:'ordersListUpdate',
    path:'update',
    meta:{
      
      M_TITLE:{modifier:'update',type: 'order'},
      M_REQUESTS:{load:'products/loadAllProducts',register:'orders/updateOrderItems'},
      M_RESQUEST_DATA_FILTER:{
        productSellingPrice: true,
        register:[
          {name:'id',substitute:'item_id'},
          {name:'unit_selling_price',substitute:'price'},
          {name:'quantity',substitute:'quantity'},
          {name:'name', substitute:'name'}
        ]
      },
      M_RESPONSE_DATA_FILTER:{listName:'order_items',},
      RENDER_INPUTS:{
        comment: {type:'description',substitute:'comment', persistence: true},
      },
    },
    component: () => import('../components/Modals/ModalContainer.vue')
  }

}

export const ModalMultiTab = {
  orders:{
    name:'ordersMultiTab',
    path:'sell',
    meta:{
      M_TITLE:{modifier:'sell',type: 'order'},
      modifier:'sell',
      type: 'order',
    },
    
    component: () => import('../components/Modals/ModalContainer.vue')
  }

}

export const ModalCreate = {
  creditTab:{
    name: 'tabCredit',
    path: 'addCredit',
    meta:{
      M_TITLE:{modifier:'add',type: 'credit to tab'},
      M_REQUESTS:{create:'credits/addCreditRecord'},
      RENDER_INPUTS:{
        value: {type:'text',substitute:'amount', validate:true},description: {type:'description',substitute:'description'},
      },
    },
    component: () => import('@/components/Modals/ModalContainer.vue')
  },
  debitTab:{
    name: 'tabDebit',
    path: 'addDebit',
    meta:{
      M_TITLE:{modifier:'add',type: 'debit to tab'},
      M_REQUESTS:{create:'credits/addDebitRecord'},
      RENDER_INPUTS:{
        value: {type:'text',substitute:'amount',validate:true},description: {type:'description',substitute:'description'},
      },
    },
    component: () => import('@/components/Modals/ModalContainer.vue')
  },
  
}